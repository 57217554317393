import { reactive } from "vue";
import router from "@/router";
import { IUnknownObject } from "@/@types/common";

let searchParams: URLSearchParams;

export function getAll(key?: string) {
    if (typeof key === 'string') {
        return searchParams.getAll(key);
    } else {
        let query = {};
        for (let [key, value] of searchParams.entries()) {
            Object.defineProperty(query, key, {
                value: value,
                enumerable: true,
                configurable: true,
            });
        }
        return query;
    }
}

export function clear() {
    for (let pair of searchParams.entries()) {
        removeParam(pair[0]);
    }
}

export function setParam(key: string, value: any) {
    searchParams.set(key, value);
}

export function setParams(params: IUnknownObject) {
    clear();
    Object.keys(params).forEach((i: string) => setParam(i, params[i]));
}

export function getParam(key: string) {
    return searchParams.get(key) || null;
}

export function hasParam(key: string) {
    return searchParams.has(key);
}

export function removeParam(key: string) {
    searchParams.delete(key);
}

export function paramsToString() {
    return searchParams.toString();
}

export function syncParams() {
    router.push({ query: getAll() });
}

export function syncSetParam(key: string, value: any) {
    setParam(key, value);
    syncParams();
}

export function syncSetParams(params: IUnknownObject) {
    setParams(params);
    syncParams();
}

export function useSearchParams() {
    searchParams = new URLSearchParams(window.location.search);

    return {
        setParam,
        setParams,
        syncParams,
        syncSetParam,
        syncSetParams,
        getParam,
        hasParam,
    };
}
