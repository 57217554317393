import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core';
import { App } from "vue";

const breakpoints = useBreakpoints(breakpointsBootstrapV5);

export const sm = breakpoints.smaller('sm');
export const md = breakpoints.between('sm', 'md');
export const lg = breakpoints.between('md', 'lg');
export const xl = breakpoints.between('lg', 'xl');
export const xxl = breakpoints.between('xl', 'xxl');
export const xxxl = breakpoints.greater('xxl');

const devices = useBreakpoints({
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
});

export const isMobile = devices.smaller('tablet');
export const isNotMobile = devices.greater('tablet');
export const isTablet = devices.between('tablet', 'laptop');
export const isLaptop = devices.between('laptop', 'desktop');
export const isDesktop = devices.greater('desktop');

export const Devices = {
    install(Vue: App) {
        //@ts-ignore
        Vue.config.globalProperties.$sm = sm;
        //@ts-ignore
        Vue.config.globalProperties.$md = md;
        //@ts-ignore
        Vue.config.globalProperties.$lg = lg;
        //@ts-ignore
        Vue.config.globalProperties.$xl = xl;
        //@ts-ignore
        Vue.config.globalProperties.$xxl = xxl;
        //@ts-ignore
        Vue.config.globalProperties.$xxxl = xxxl;
        //@ts-ignore
        Vue.config.globalProperties.$isMobile = isMobile;
        //@ts-ignore
        Vue.config.globalProperties.$isNotMobile = isNotMobile;
        //@ts-ignore
        Vue.config.globalProperties.$isTablet = isTablet;
        //@ts-ignore
        Vue.config.globalProperties.$isLaptop = isLaptop;
        //@ts-ignore
        Vue.config.globalProperties.$isDesktop = isDesktop;
    },
};
