import { io } from "socket.io-client";
import { App, reactive, ref } from "vue";
import { SOCKET_URL } from "@/configs/app";
import { IUnknownObject } from "@/@types/common";
import { log } from "@/utils/log";

export const socket: any = ref(null);

export async function socketEmit(emit: string, data: IUnknownObject = {}) {
    log('socket:emit', emit, data);
    return new Promise((resolve, reject) => {
        if (socket.value == null) {
            reject('No socket connection.');
        } else {
            socket.value.emit(emit, data, (response: any) => {
                if (response.ok) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            });
        }
    });
}

export async function socketOn(emit: string, callback = (r: any) => {}) {
    log('socket:on', emit);
    socket.value.on(emit, callback);
}

export function socketOff(emit: string) {
    log('socket:off', emit);
    socket.value.off(emit);
}

export async function socketOn2(emit: string) {
    log('socket:on', emit);
    return new Promise((resolve, reject) => {
        if (socket.value === null) {
            reject('No socket connection.');
        } else {
            socket.value.on(emit, (response: any) => {
                if (response.ok) {
                    resolve(response.data);
                } else {
                    reject(response.error);
                }
            });
        }
    });
}

export const Socket = {
    install(Vue: App) {
        socket.value = io(SOCKET_URL, {
            auth: {
                token: localStorage.getItem('token'),
            },
        });

        socket.value.on('connect', () => {
            log('socket:connection', 'Сокетное соединение установлено');
        });

        socket.value.on("connect_error", () => {
            log('danger', 'Ошибка подключения к сокету');
            setTimeout(() => socket.value.connect(), 1000);
        });
    },
};
