import { IUnknownObject } from "@/@types/common";

interface IColor {
    fill: string,
    color: string
}

const colors: IColor[] = [
    { fill: '#ef4444', color: adjust('#ef4444', 150) },
    { fill: '#f97316', color: adjust('#f97316', 150) },
    { fill: '#f59e0b', color: adjust('#f59e0b', 150) },
    { fill: '#eab308', color: adjust('#eab308', 150) },
    { fill: '#84cc16', color: adjust('#84cc16', 150) },
    { fill: '#22c55e', color: adjust('#22c55e', 150) },
    { fill: '#10b981', color: adjust('#10b981', 150) },
    { fill: '#14b8a6', color: adjust('#14b8a6', 150) },
    { fill: '#06b6d4', color: adjust('#06b6d4', 150) },
    { fill: '#0ea5e9', color: adjust('#0ea5e9', 150) },
    { fill: '#3b82f6', color: adjust('#3b82f6', 150) },
    { fill: '#6366f1', color: adjust('#6366f1', 150) },
    { fill: '#8b5cf6', color: adjust('#8b5cf6', 150) },
    { fill: '#a855f7', color: adjust('#a855f7', 150) },
    { fill: '#d946ef', color: adjust('#d946ef', 150) },
    { fill: '#ec4899', color: adjust('#ec4899', 150) },
    { fill: '#f43f5e', color: adjust('#f43f5e', 150) },
];

function adjust(color: string, amount: number): string {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export default function (str: string): IColor {
    if (str === '' || str === null) return { fill: '#222e3c', color: '#fff' };
    let count = str.split('').reduce((r, c) => r + c.charCodeAt(0), 0);
    return colors[count % colors.length];
}
