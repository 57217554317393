import { notify } from "@kyvg/vue3-notification";

export default {
    show(type: string, title:string, text: string): void {
        notify({
            type: type,
            title: title,
            text: text,
            duration: 10000,
        });
    },
    success(title: string, text: string): void {
        this.show('success', title, text);
    },
    warn(title: string, text: string): void {
        this.show('warn', title, text);
    },
    error(title: string, text: string): void {
        this.show('error', title, text);
    },
};