import { ref } from 'vue';
import { createI18n } from 'vue-i18n';
import { useCookies } from "vue3-cookies";
import { SUPPORT_LOCALES, DEFAULT_LOCALE } from "@/configs/locales";
import ru from '@/locales/ru';
import en from '@/locales/en';
import kz from '@/locales/kz';

const { cookies } = useCookies();
const supportLocales = SUPPORT_LOCALES.map(i => i.code);
export const lang: any = ref(cookies.get('lang') || DEFAULT_LOCALE);

if (!supportLocales.includes(lang.value)) {
    cookies.set('lang', DEFAULT_LOCALE);
}

export const i18nInstance = createI18n({
    locale: lang.value,
    fallbackLocale: DEFAULT_LOCALE,
    globalInjection: true,
    pluralizationRules: { ru: pluralizationRules, kk: pluralizationRules },
    messages: { ru, en, kz },
});

export const i18n = i18nInstance.global;

export function setLocale(code: string) {
    const { cookies } = useCookies();
    lang.value = code;
    if (!supportLocales.includes(code)) {
        lang.value = DEFAULT_LOCALE;
    }
    cookies.set('lang', lang.value);
    if (i18nInstance.mode === 'legacy') {
        i18nInstance.global.locale = lang.value;
    } else {
        i18nInstance.global.locale.value = lang.value;
    }
}

function pluralizationRules(choice: any, choicesLength:any) {
    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
    }
    if (!teen && endsWithOne) {
        return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
    }

    return (choicesLength < 4) ? 2 : 3;
}
